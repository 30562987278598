import "@fontsource/inter";
import { CssBaseline } from "@material-ui/core";
import { AppProps } from "next/app";
import Head from "next/head";
import * as React from "react";
import { iLayoutFC } from "@common/types";
// Turn off offline mode for a while, will re enable this soon
// import { startWorker } from "../src/offline/worker";
import "./print.css";
import "./global.css";
import OfflineInitialDataFetcher from "@miscellaneous/offline/components/OfflineInitialDataFetcher";
import OfflineRefreshModal from "@miscellaneous/offline/components/OfflineRefreshModal/OfflineRefreshModal";
import UserIdTracker from "@miscellaneous/tracking/components/UserIdTracker/UserIdTracker";
import { AppProvider } from "@common/context";
import Script from "next/script";
import { GTM_ID } from "../lib/gtm";

interface iAppProps extends AppProps {
  Component: iLayoutFC | React.VFC;
}

// START WORKER
// startWorker();

const MyApp: React.VFC<iAppProps> = ({ Component, pageProps }) => {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const getLayout =
    (Component as iLayoutFC).getLayout || ((page: React.ReactElement) => page);

  return (
    <>
      <Head>
        <title>GudangAda Solusi</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-SemiBold.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Bold.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>
      <AppProvider>
        <UserIdTracker />
        <CssBaseline />
        <OfflineRefreshModal />
        {!(Component as iLayoutFC).excludeOfflineFetcher && (
          <OfflineInitialDataFetcher />
        )}
        {/* Google Tag Manager - Global base code */}
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
        {getLayout(<Component {...pageProps} />)}
      </AppProvider>
    </>
  );
};

export default MyApp;
